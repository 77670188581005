// An import to index of phaf-runtime-helpers is required to get PHAF declarations.
import '@verily-src/phaf-runtime-helpers';
import {config} from '@verily-src/phaf-runtime-helpers/src/mfe_helpers/configurationWrapper';
import {registerApplication, start} from 'single-spa';
import {
  constructApplications,
  constructLayoutEngine,
  constructRoutes,
} from 'single-spa-layout';
import {HTMLLayoutData} from 'single-spa-layout/dist/types/isomorphic/constructRoutes';
import './microfrontend-layout.css';
import microfrontendLayout from './microfrontend-layout.html';

System.import('@verily-src/auth').then(module => {
  const Auth = module.Auth;
  const domain = config.getString('DOMAIN');
  const clientId = config.getString('CLIENT_ID');
  const audience = config.getString('AUDIENCE');
  const redirectUri = `${window.location.origin}/enrollment/authCallback`;
  Auth.setUp({
    domain: domain,
    clientId: clientId,
    redirectUri: redirectUri,
    audience: audience,
    usingReactAuthMFE: false,
    enableAutoLogout: false,
    appName: 'enrollment-web',
  });
});

if (config.getBoolean('FEATURE_ANALYTICS_MFE_ENABLED')) {
  System.import('@verily-src/analytics');
}

const data: HTMLLayoutData = {
  props: {
    nav: {},
    routes: {},
  },
  loaders: {},
};

// Construct routes & applications, passing in props data.
// Route activation is set up using microfrontend layout route options.
const routes = constructRoutes(microfrontendLayout, data);
const applications = constructApplications({
  routes,
  loadApp({name}) {
    return System.import(name);
  },
});
const layoutEngine = constructLayoutEngine({routes, applications});

applications.forEach(registerApplication);
layoutEngine.activate();

start();
